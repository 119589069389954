<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0" @scroll.passive="handleScroll">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="displayedData"
            :fields="fields"
            @row-dblclicked="editForm"
            @row-contextmenu="handleContextMenuEvent"
            @row-clicked="clicked"
            head-row-variant="secondary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(active)="data">
              <span v-if="data.item.active == 1"> Работает </span>
              <span v-else> Не работает </span>
            </template>
            <template #cell(car_drivers)="data">
              <div
                @dblclick="getDrivers(data)"
                class="text-primary"
                v-for="drivers in data.item.car_drivers"
                :key="drivers.id"
              >
                {{ drivers.first_name + " " + drivers.last_name }};
              </div>
            </template>
            <template #cell(history)="data">
              <span @dblclick="getHistory(data)" class="reference-field">
                (***)
              </span>
            </template>
            <template #cell(photo_controls)="data">
              <span @dblclick="getPhoto(data)" class="reference-field">
                (***)
              </span>
            </template>
            <template #cell(model)="data">
              <span>
                {{ data.item.car_brand + " " + data.item.model }}
              </span>
            </template>
            <template #cell(dop_options)="data">
              <span v-for="options in data.item.dop_options" :key="options.id">
                {{ options.name + ", " }}
              </span>
            </template>
            <template #cell(weight)="data">
              <div v-if="data.item.cargo_properties">
                {{ data.item.cargo_properties.carrying_capacity }}
              </div>
            </template>
            <template #cell(length)="data">
              <span v-if="data.item.cargo_properties">
                {{ data.item.cargo_properties.length }}
              </span>
            </template>
            <template #cell(height)="data">
              <span v-if="data.item.cargo_properties">
                {{ data.item.cargo_properties.height }}
              </span>
            </template>
            <template #cell(width)="data">
              <span v-if="data.item.cargo_properties">
                {{ data.item.cargo_properties.width }}
              </span>
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link
                class="d-flex align-items-center"
                v-b-modal="$route.name + 'CreateModal'"
              >
                <feather-icon icon="PlusIcon" size="16" />
                <span class="ml-75">Добавить</span>
              </b-link>
              <b-link class="d-flex align-items-center" @click="editCar()">
                <feather-icon icon="EditIcon" size="16" />
                <span class="ml-75">Изменить</span>
              </b-link>
              <b-link class="d-flex align-items-center" @click="editCar()">
                <feather-icon icon="Edit3Icon" size="16" />
                <span class="ml-75">Изменить подразделение</span>
              </b-link>
              <b-link
                @click="addPhotocontrol()"
                v-b-modal.photocontrolModal
                class="d-flex align-items-center"
              >
                <feather-icon icon="CameraIcon" size="16" />
                <span class="ml-75">Назначить фотоконтроль</span>
              </b-link>
              <b-link v-b-modal.ViewCar class="d-flex align-items-center">
                <feather-icon icon="EyeIcon" size="16" />
                <span class="ml-75">Просмотр</span>
              </b-link>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.css";
import VueContext from "vue-context";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import lazyLoading from "@/@core/mixins/utils/lazyLoading";
export default {
  mixins: [lazyLoading],
  props: ["dataTable"],
  components: {
    VueContext,
    ToastificationContent,
  },
  data() {
    return {
      openCar: false,
      id: "",
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        {
          key: "division",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "org_type",
          label: "Тип организации",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "model",
          label: "Модель",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "color",
          label: "Цвет",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "car_number",
          label: "Номер машины",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "year_of_issue",
          label: "Год выпуска",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "condition",
          label: "Состояние",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "body_type",
          label: "Тип кузова",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "fuel_type_name",
          label: "Тип топлива",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "class_car",
          label: "Класс авто",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "car_drivers",
          label: "Водители",
          sortable: true,
          thStyle: { width: "150px" },
        },
        // {key: 'category', label: 'Категория', sortable: true, thStyle: {width: '150px'}},
        {
          key: "dop_options",
          label: "Доп. параметры",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "history",
          label: "История",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "weight",
          label: "Грузоподъемность(т)",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "length",
          label: "Длина(м)",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "width",
          label: "Ширина(м)",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "height",
          label: "Высота(м)",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "photo_controls",
          label: "Фотоконтроль",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "pictures",
          label: "Изображения",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "date_add",
          label: "Дата приема",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "date_in_office",
          label: "Дата осмотра в офисе",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "count_seat",
          label: "Количество мест",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "VIN",
          label: "VIN",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "STS_N",
          label: "№ CТС",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "technical_inspection",
          label: "Тех. осмотр",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "license_number",
          label: "Номер лицензии",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "region",
          label: "Регион выдачи",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "licensors",
          label: "Лицензиары",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "legal_entity",
          label: "Юридическое лицо",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  methods: {
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.id = item;
    },
    editForm(id) {
      this.$emit("editForm", id.id);
    },
    editCar(id) {
      this.$emit("editForm", this.id.id);
    },
    addPhotocontrol() {
      this.$emit("addPhotocontrol", this.id);
    },
    getDrivers(data) {
      this.$router.push({
        name: "driverInfo",
        query: { filter: data.item.id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Водитель " + data.item.id,
        `/driver/driverInfo`,
        data.item.id,
      ]);
    },
    getHistory(data) {
      this.$router.push({
        name: "carChangeHistory",
        query: { car_id: data.item.id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "История " + data.item.id,
        `/cars/changeHistory`,
        data.item.id,
      ]);
    },
    getPhoto(data) {
      this.$router.push({
        name: "photo-control/performer-assign/searchInfo",
        query: { filter: data.item.performer_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Фотоконтроль " + data.item.performer_id,
        `/taxi/photo-controlInfo`,
        data.item.performer_id,
      ]);
    },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.textContent.trim();
      cellSelect();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/table.scss";
.my-card {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
